import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Login from '../views/Login'
import { EaseChatClient } from '@/IM/initwebsdk'
import { fetchAnalyze } from '@/api/login'
import { usePlayRing } from '@/hooks'
console.log(store.state.loginState)
const routes = [
    {
        path: '/',
        name: 'chat',
        component: () => import('../views/Chat'),
    },
    /* 登陆页 */
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: () => import('../views/Login'),
    //     meta: {
    //         title: '登陆',
    //     },
    // },
    /* 聊天页 */
    {
        path: '/chat',
        name: 'Chat',
        redirect: '/chat/conversation',
        component: () => import('../views/Chat'),
        meta: {
            title: '开始聊天',
        },
        children: [
            /* 会话列表 */
            {
                path: 'conversation',
                name: 'Conversation',
                meta: {
                    title: '会话',
                    requiresAuth: true,
                },
                component: () => import('../views/Chat/components/Conversation'),
                children: [
                    //系统通知详情框
                    {
                        path: 'informdetails',
                        component: () => import('../views/Chat/components/InformDetails'),
                    },
                    //聊天对话框
                    {
                        path: 'message',
                        component: () => import('../views/Chat/components/Message'),
                    },
                ],
            },
            /* 联系人页 */
            {
                path: 'contacts',
                name: 'Contacts',
                meta: {
                    title: '联系页',
                    requiresAuth: true,
                },
                component: () => import('../views/Chat/components/Contacts'),
                children: [
                    {
                        path: 'message',
                        component: () => import('../views/Chat/components/Message'),
                    },
                    {
                        path: 'contactInfo',
                        component: () =>
                            import(
                                '../views/Chat/components/Contacts/components/contactInfo.vue'
                            ),
                    },
                ],
            },
            /* 联系人页 */
            {
                path: 'group',
                name: 'Group',
                meta: {
                    title: '群组',
                    requiresAuth: true,
                },
                component: () => import('../views/Chat/components/Group'),
                children: [
                    {
                        path: 'message',
                        component: () => import('../views/Chat/components/Message'),
                    },
                    {
                        path: 'contactInfo',
                        component: () =>
                            import(
                                '../views/Chat/components/Group/components/contactInfo.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

const fetchUserAnalyze = async (uid, next) => {
    const { clickRing } = usePlayRing()
    clickRing()
    try {
        const res = await fetchAnalyze({ uid })
        if (res?.code === 200) {
            console.log('>>>>>>解析成功', res.data)
            window.localStorage.removeItem('EASEIM_loginUser')
            window.localStorage.setItem('EASEIM_loginUser',
                JSON.stringify({
                    user: res.data.emchat_username.toLowerCase(),
                    accessToken: res.data.userToken,
                    avatarurl: res.data.head_picture,
                    username: res.data.nickname
                })
            )
            EaseChatClient.open({
                user: res.data.emchat_username.toLowerCase(),
                accessToken: res.data.userToken,
            })
            next()
        }
    } catch (error) {
        console.log('>>>>登陆失败', error)
    }
}

const getUrlParams = (url) => {
    // 通过 ? 分割获取后面的参数字符串
    const urlStr = url.split('?')[1]
    // 创建空对象存储参数
    const obj = {}
    // 再通过 & 将每一个参数单独分割出来
    const paramsArr = urlStr.split('&')
    paramsArr.map(item => {
        // 再通过 = 将每一个参数分割为 key:value 的形式
        const arr = item.split('=')
        obj[arr[0]] = arr[1]
    })
    return obj
}

router.beforeEach((to, from, next) => {
    // const loginState = store.state.loginState
    const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
    const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
    if (to.name === 'chat' && to.fullPath.split('?')[1]) {
        const urlParams = getUrlParams(to.fullPath)
        urlParams.uid && fetchUserAnalyze(urlParams.uid, next)
    } else {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            //需要登陆
            if (loginUserFromStorage.user && loginUserFromStorage.accessToken) {
                //token存在，放行
                next()
            } else {
                //token不存在，跳转登陆页面
                next({ path: '/' })
            }
        } else {
            next()
        }
    }

})
export default router
