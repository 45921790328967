import request from '@/utils/request'

//获取用户登录token
export function fetchUserLoginToken(params) {
    return request({
        url: '/api/user/login',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: params
    })
}
//解析
export function fetchAnalyze(params) {
    return request({
        url: '/api/user/user_detail',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: params
    })
}
//获取短信验证码
export function fetchUserLoginSmsCode(phoneNumber) {
    return request({
        url: `/inside/app/sms/send/${phoneNumber}`,
        method: 'post'
    })
}
