import request from '@/utils/request'

//用户或群组信息
export function fetchUserOrGroupInfo(params) {
    return request({
        url: '/api/user/info_detail',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: params
    })
}